import React, { useState,useEffect } from "react";
import axios from "axios"; // Import axios
import toast, { Toaster } from "react-hot-toast"; // Import react-hot-toast
import "mdb-react-ui-kit/dist/css/mdb.min.css"; // Ensure MDBootstrap CSS is imported
import "@fortawesome/fontawesome-free/css/all.min.css"; // For icons
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from 'js-cookie'; // Import js-cookie
import ReCAPTCHA from "react-google-recaptcha";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState(""); // Add state for Name
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // Add state for Confirm Password
  const [phoneNumber, setPhoneNumber] = useState(""); // Add state for Phone Number
  const [reviewerName, setReviewerName] = useState(""); // Add state for Reviewer Name
  const [rememberMe, setRememberMe] = useState(false);
  const [referralId, setReferralId] = useState(null); // State for Referral ID
  const history = useHistory(); // Initialize useHistory

  const [value, setvalue] = useState(null);
  function onChange(value) {
    console.log("Captcha value:", value);
    setvalue(value);
  }

    // Get the query parameters from the URL
    const location = useLocation();

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const refId = params.get('id'); // Extract referral ID
    
      // Only set the referral ID if it is different from the current one
      if (refId && refId !== referralId) {
        setReferralId(refId);
      }
    }, [location.search]); // Removed referralId from dependencies
    

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    if (value === null) {
      toast.error("Please verify captcha");
      return;
    }

    // Create the data object
    const userData = {
      Email: email,
      Name: name,
      Password: password,
      PhoneNumber: phoneNumber,
      ReviewerName: reviewerName,
      referralId: referralId || null, // Add Referral ID here
    };

    try {
      console.log("Sending user data: ", userData); // Log the data being sent
      // Send a POST request to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_URI}/SignUp`,
        userData
      );
      Cookies.set("SignUpId", response.data.userId, {
        expires: 7,
      });
      Cookies.set("hashedOtp", response.data.hashedOtp, {
        expires: 7,
      });


      // Show success toast
      toast.success(response.data.message);
      if(response.data.message !== 'User with this email or phone number already exists'){
      setName("");
      setRememberMe(false);
      setConfirmPassword("");
      setPassword("");
      setEmail("");
      setPhoneNumber("");
      setReviewerName("");
      history.push('/verify');
      }
      

      // Optionally, redirect to login page or another page
    } catch (error) {
      console.error("Error during signup: ", error.response ? error.response.data : error.message);
  toast.error("Error during signup: ", error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <Toaster /> {/* Add the Toaster component to render the toasts */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "80px",
        }}
      >
        <img
          src="./assets/logo.png"
          style={{ width: "70px", height: "auto" }}
          alt=""
        />
      </div>
      <h1 style={{ color: "#386bc0", marginTop: "20px" }}> Sign Up</h1>
      <div
        className="row"
        style={{
          marginLeft: "0px",
          marginRight: "0px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "60px",
        }}
      >
        <form
          action="#"
          className="contact_form"
          id="contact_form"
          onSubmit={handleSubmit}
        >
          <div className="col-lg-3 col-md-6 mx-auto text-left">
            <div data-mdb-input-init className="form-outline mb-2">
              <input
                type="text"
                id="form2Example1"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  backgroundColor: "#e8e8e8",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "30px",
                }}
                required
                placeholder="Enter Name"
              />
            </div>

            <div data-mdb-input-init className="form-outline mb-2">
              <input
                type="email"
                id="form2Example2"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  backgroundColor: "#e8e8e8",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "30px",
                }}
                required
                placeholder="Enter Email"
              />
            </div>

            <div data-mdb-input-init className="form-outline mb-2">
              <input
                type="password"
                id="form2Example3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{
                  backgroundColor: "#e8e8e8",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "30px",
                }}
                placeholder="Enter Password"
              />
            </div>

            <div data-mdb-input-init className="form-outline mb-2">
              <input
                type="password"
                id="form2Example4"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                style={{
                  backgroundColor: "#e8e8e8",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "30px",
                }}
                placeholder="Confirm Password"
              />
            </div>

            <div data-mdb-input-init className="form-outline mb-2">
              <input
                type="text"
                id="form2Example5"
                value={reviewerName}
                onChange={(e) => setReviewerName(e.target.value)}
                required
                style={{
                  backgroundColor: "#e8e8e8",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "30px",
                }}
                placeholder="Default Reviewer Name"
              />
            </div>

            <div data-mdb-input-init className="form-outline mb-4">
              <input
                type="text"
                id="form2Example6"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                style={{
                  backgroundColor: "#e8e8e8",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "30px",
                }}
                placeholder="Phone Number"
              />
            </div>

            <ReCAPTCHA
                sitekey="6Le7eFgqAAAAADu2nRhLGVGLwpy0hm7mZ8HRGDea"
                onChange={onChange}
                style={{ background: "none", marginTop: "20px", width: "100%" }}
              />

            {/* <div className="row mb-4">
              <div className="col d-flex justify-content-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="form2Example31"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label className="form-check-label" htmlFor="form2Example31">
                    Remember me
                  </label>
                </div>
              </div>
            </div> */}

            <button
              type="submit"
              className="btn btn-primary btn-block mb-4"
              style={{
                backgroundColor: "#386bc0",
                textAlign: "center",
                color: "#000",
                width: "100%",
                padding: "10px",
                marginTop: "15px",
                borderRadius: "30px",
              }}
            >
              Sign up
            </button>

            <div className="text-center">
              <p>
                Already have an account?{" "}
                <Link to="/" style={{ color: "#386bc0" }}>
                  Login
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
