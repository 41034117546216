// src/App.js
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import SignUp from './Components/SignUp';
import Login from './Components/Login';
import { Home } from './Components/Home';
import Update from './Components/Update';
import NewOrder from './Components/NewOrder';
import Profile from './Components/Profile';
import UpdateProfile from './Components/UpdateProfile';
import Allotment from './Components/Allotment';
import Password from './Components/Password';
import Refund from './Components/Refund';
import Refer from './Components/Refer';
import './App.css';
import ForgotPassword from './Components/ForgotPassword';
import VerifyOTP from './Components/VerifyOTP';
import UpdatePassword1 from './Components/UpdatePassword1';
import VerifyOtp1 from './Components/VerifyOtp1';
import OAuthRedirectHandler from './Components/authorize';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/SignUp" component={SignUp} />
            <Route exact path="/" component={Login} />
            <Route exact path="/Home" component={Home} />
            <Route exact path="/Update" component={Update} />
            <Route exact path="/NewOrder" component={NewOrder} />
            <Route exact path="/Profile" component={Profile} />
            <Route exact path="/UpdateProfile" component={UpdateProfile} />
            <Route exact path="/Allotment" component={Allotment} />
            <Route exact path="/Password" component={Password} />
            <Route exact path="/Refund" component={Refund} />
            <Route exact path="/Refer" component={Refer} />
            <Route exact path="/ForgotPassword" component={ForgotPassword} />
            <Route exact path="/VerifyOTP" component={VerifyOTP} />
            <Route exact path="/UpdatePassword1" component={UpdatePassword1} />
            <Route exact path="/verify" component={VerifyOtp1} />
            <Route exact path="/authorize" component={OAuthRedirectHandler} />
          </Switch>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
