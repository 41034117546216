import React, { useEffect, useState } from "react";
import { Sidebarr } from "./Sidebar";
import Cookies from "js-cookie"; // to get the userId from cookies
import toast, { Toaster } from "react-hot-toast"; // for toast notifications

export default function UpdateProfile() {
  const [fullName, setFullName] = useState("");
  const [defaultReviewerName, setDefaultReviewerName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state for button spinner

  const userId = Cookies.get("userId"); // Get userId from cookies

  // Fetch the data from the API and set the state
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URI}/GetProfile/${userId}`
        );
        const data = await response.json();

        // Set the state with the fetched data
        setFullName(data.Name || "");
        setDefaultReviewerName(data.ReviewerName || "");
        setEmail(data.Email || "");
        setWhatsappNumber(data.PhoneNumber || "");
        setAlternateNumber(data.AlternateNumber || "");
        setAccountName(data.AccountName || "");
        setAccountNumber(data.AccountNumber || "");
        setIfsc(data.IFSC || "");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show spinner

    const updatedProfile = {
      Name: fullName,
      ReviewerName: defaultReviewerName,
      Email: email,
      PhoneNumber: whatsappNumber,
      AlternateNumber: alternateNumber,
      AccountName: accountName,
      AccountNumber: accountNumber,
      IFSC: ifsc,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URI}/UpdateProfile/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedProfile),
        }
      );

      if (response.ok) {
        toast.success("Profile updated successfully!");
      } else {
        toast.error("Failed to update profile.");
      }
    } catch (error) {
      toast.error("Error updating profile.");
      console.error("Error updating profile:", error);
    } finally {
      setIsLoading(false); // Hide spinner
    }
  };

  return (
    <div>
      <div className="container">
        {/* Sidebar with a fixed width */}
        <div className="sidebar">
          <Sidebarr />
        </div>

        {/* Main content */}
        <div className="main-content">
          <h1 className="dashboard-title">Update Profile</h1>

          <div
            className="row"
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "60px",
            }}
          >
            <div className="col-lg-6 col-md-6 mx-auto text-left">
              <form onSubmit={handleSubmit}>
                {/* Input fields */}
                {/* Full Name */}
                <div className="mb-2">
                  <label htmlFor="" style={{color:'#000',fontWeight:'bold'}}> Full Name</label>
                  <input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    required
                    placeholder="Full Name"
                  />
                </div>

                {/* Default Reviewer Name */}
                <div className="mb-2">
                <label htmlFor="" style={{color:'#000',fontWeight:'bold'}}> Reviewer Name</label>
                  <input
                    type="text"
                    value={defaultReviewerName}
                    onChange={(e) => setDefaultReviewerName(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    required
                    placeholder="Default Reviewer Name"
                  />
                </div>

                {/* Email */}
                <div className="mb-2">
                <label htmlFor="" style={{color:'#000',fontWeight:'bold'}}> Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    required
                    placeholder="Email"
                  />
                </div>

                {/* Whatsapp Number */}
                <div className="mb-2">
                <label htmlFor="" style={{color:'#000',fontWeight:'bold'}}>Whatsapp Number</label>
                  <input
                    type="text"
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    required
                    placeholder="Whatsapp Number"
                  />
                </div>

                {/* Alternate Number */}
                <div className="mb-2">
                <label htmlFor="" style={{color:'#000',fontWeight:'bold'}}>Alternate Number</label>
                  <input
                    type="text"
                    value={alternateNumber}
                    onChange={(e) => setAlternateNumber(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    placeholder="Alternate Number"
                  />
                </div>

                {/* Account Name */}
                <div className="mb-2">
                <label htmlFor="" style={{color:'#000',fontWeight:'bold'}}>Account Name</label>
                  <input
                    type="text"
                    value={accountName}
                    onChange={(e) => setAccountName(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    placeholder="Account Name"
                  />
                </div>

                {/* Account Number */}
                <div className="mb-2">
                <label htmlFor="" style={{color:'#000',fontWeight:'bold'}}>Account Number</label>
                  <input
                    type="text"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    placeholder="Account No"
                  />
                </div>

                {/* IFSC */}
                <div className="mb-2">
                <label htmlFor="" style={{color:'#000',fontWeight:'bold'}}>IFSC</label>
                  <input
                    type="text"
                    value={ifsc}
                    onChange={(e) => setIfsc(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    placeholder="IFSC"
                  />
                </div>

                {/* Save Button with spinner */}
                <button
                  type="submit"
                  className="btn-primary btn-block mb-4"
                  style={{
                    backgroundColor: "#386bc0",
                    textAlign: "center",
                    color: "#fff",
                    width: "100%",
                    padding: "10px",
                    marginTop: "15px",
                    borderRadius: "30px",
                    position: "relative",
                  }}
                  disabled={isLoading} // Disable button while loading
                >
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Toaster for notifications */}
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}
