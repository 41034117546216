import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie"; // Import js-cookie
import { Sidebarr } from "./Sidebar";
import { Spinner } from "react-bootstrap";

export default function Profile() {
  const [profileData, setProfileData] = useState(null);
  const history = useHistory();

  const handleUpdateClick = () => {
    history.push("/UpdateProfile"); // Navigate to UpdateProfile route
  };

  // Fetch profile data using ID from cookies
  useEffect(() => {
    const userId = Cookies.get("userId"); // Get user ID from cookies

    if (userId) {
      // Fetch the profile data using the ID
      fetch(`${process.env.REACT_APP_URI}/GetProfile/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setProfileData(data); // Set the profile data
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    } else {
      history.push("/"); // Redirect to login if no user ID is found in cookies
    }
  }, [history]);

  // If profile data is not loaded yet, show loading message
  if (!profileData) {
    return (
      <div className="container">
        {/* Sidebar with a fixed width */}
        <div className="sidebar">
          <Sidebarr />
        </div>
        <div
          className="main-content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height to center the spinner
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      {/* Sidebar with a fixed width */}
      <div className="sidebar">
        <Sidebarr />
      </div>

      {/* Main content */}
      <div
        className="main-content"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "calc(100vh - 60px)", // Adjust based on sidebar height
          padding: "20px",
        }}
      >
        <h1 className="dashboard-title" style={{marginTop:'100px'}}>Profile</h1>

        {/* Profile Information Card */}
        <div
          className="profile-card"
          style={{
            backgroundColor: "#f4f4f4",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <h3 style={{ fontWeight: "bold" }}>Profile Information</h3>

          {/* Display the fetched profile information */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <strong>Full Name:</strong>
              <span>{profileData.Name}</span>
            </div>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <strong>Email:</strong>
              <span>{profileData.Email}</span>
            </div>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <strong>Phone Number:</strong>
              <span>{profileData.PhoneNumber}</span>
            </div>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <strong>Alternate Contact:</strong>
              <span>
                {profileData.AlternateNumber === null
                  ? "---"
                  : profileData.AlternateNumber}
              </span>
            </div>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <strong>Paytm/GPay/PhonePe:</strong>
              <span>
                {profileData.AccountNumber === null
                  ? "---"
                  : profileData.AccountNumber}
              </span>
            </div>
          </div>

          {/* Update Button */}
          <button
            type="button"
            className="btn btn-primary btn-block"
            style={{
              backgroundColor: "#386bc0",
              textAlign: "center",
              color: "#fff",
              width: "100%",
              padding: "10px",
              borderRadius: "30px",
              border: "none",
              marginTop: "20px",
            }}
            onClick={handleUpdateClick}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}
