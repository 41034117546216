import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

export default function VerifyOtp() {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false); // Spinner for form submission
  const history = useHistory();

  // Handle OTP verification
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner during submission

    // Get hashedOtp from cookies
    const hashedOtp = Cookies.get("hashedOtp");

    try {
      // Send the OTP and hashed OTP to the backend for verification
      const response = await axios.post(`${process.env.REACT_APP_URI}/VerifyOtp`, { otp, hashedOtp });

      // If OTP is verified successfully
      if (response.status === 200) {
        // toast.success(response.data.message); // Show success message

        // Redirect to UpdatePassword page
        history.push("/UpdatePassword1");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Failed to verify OTP. Please try again later.");
    } finally {
      setLoading(false); // Hide spinner after submission
    }
  };

  return (
    <div>
      <Toaster />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "80px",
        }}
      >
        <img
          src="./assets/logo.png"
          style={{ width: "70px", height: "auto" }}
          alt="logo"
        />
      </div>

      <h1 style={{ color: "#386bc0", marginTop: "20px" }}>Verify OTP</h1>
      <div
        className="row"
        style={{
          marginLeft: "0px",
          marginRight: "0px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "60px",
        }}
      >
        <form className="contact_form" onSubmit={handleSubmit}>
          <div className="col-lg-3 col-md-6 mx-auto text-left">
            <div data-mdb-input-init className="form-outline mb-2">
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                style={{
                  backgroundColor: "#e8e8e8",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "30px",
                }}
                required
                placeholder="Enter Verification Code"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-block mb-4"
              style={{
                backgroundColor: "#386bc0",
                textAlign: "center",
                color: "#000",
                width: "100%",
                padding: "10px",
                marginTop: "15px",
                borderRadius: "30px",
              }}
              disabled={loading} // Disable button while submitting
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Verify OTP"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
