import React, { useState } from "react";
import { Sidebarr } from "./Sidebar";
import Cookies from "js-cookie"; // Import js-cookie to read cookies
import axios from "axios"; // Axios for making HTTP requests
import toast, { Toaster } from "react-hot-toast"; // Import react-hot-toast for notifications
import { ClipLoader } from "react-spinners"; // Import spinner from react-spinners

export default function Password() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for spinner

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Get the user id from cookies
    const userId = Cookies.get("userId"); // Make sure 'userId' cookie exists

    // Basic validation to check if passwords match
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }

    try {
      setLoading(true); // Start loading spinner

      // Make an API request to update the password
      const response = await axios.put(
        `${process.env.REACT_APP_URI}/UpdatePassword/${userId}`,
        {
          oldPassword: currentPassword,
          newPassword: newPassword,
        }
      );

      // Show success message using toast
      toast.success(response.data.message);
      setNewPassword(""); // Clear the new password field
      setConfirmPassword(""); // Clear the confirm password field
      setCurrentPassword(""); // Clear the current password field
    } catch (error) {
      // Show error message using toast
      toast.error(error.response?.data?.message || "Error updating password");
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <div>
      {/* Toast container */}
      <Toaster position="top-center" />

      <div className="container">
        {/* Sidebar with a fixed width */}
        <div className="sidebar">
          <Sidebarr />
        </div>

        {/* Main content */}
        <div className="main-content">
          <h1 className="dashboard-title">Password</h1>

          <div
            className="row"
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "60px",
            }}
          >
            <div className="col-lg-6 col-md-6 mx-auto text-left">
              <form onSubmit={handleSubmit}>
                <div className="mb-2">
                  <input
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    required
                    placeholder="Current Password"
                  />
                </div>

                <div className="mb-2">
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    required
                    placeholder="New Password"
                  />
                </div>

                <div className="mb-2">
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    required
                    placeholder="Confirm Password"
                  />
                </div>

                {/* Save Button with Spinner */}
                <button
                  type="submit"
                  className="btn-primary btn-block mb-4"
                  style={{
                    backgroundColor: "#386bc0",
                    textAlign: "center",
                    color: "#fff",
                    width: "100%",
                    padding: "10px",
                    marginTop: "15px",
                    borderRadius: "30px",
                  }}
                  disabled={loading}
                >
                  {loading ? <ClipLoader color="#fff" size={20} /> : "Save"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
