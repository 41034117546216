import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

export default function ForgotPassword() {
  const [Email, setEmail] = useState("");
  const [loading, setLoading] = useState(false); // To track form submission state
  const history = useHistory();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); // Show spinner when submitting

    try {
      // Send OTP to the user's email
      const response = await axios.post(`${process.env.REACT_APP_URI}/SendOtp`, { email: Email });

      if (response.data) {
        const { hashedOtp, userId } = response.data;

        // Store the hashed OTP and userId in cookies
        Cookies.set("hashedOtp", hashedOtp, { expires: 5 }); // Expires in 5 days
        Cookies.set("userforgetId", userId, { expires: 5 });

        // Show success message
        toast.success(response.data.message);

        // Redirect to VerifyOTP page
        history.push("/VerifyOTP");
      } else {
        toast.error("Error sending OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Failed to send OTP. Please try again later.");
    } finally {
      setLoading(false); // Hide spinner after form submission
    }
  };

  return (
    <div>
      <Toaster />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "80px",
        }}
      >
        <img
          src="./assets/logo.png"
          style={{ width: "70px", height: "auto" }}
          alt=""
        />
      </div>

      <h1 style={{ color: "#386bc0", marginTop: "20px" }}> Forgot Password</h1>
      <div
        className="row"
        style={{
          marginLeft: "0px",
          marginRight: "0px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "60px",
        }}
      >
        <form
          className="contact_form"
          id="contact_form"
          onSubmit={handleSubmit}
        >
          <div className="col-lg-3 col-md-6 mx-auto text-left">
            <div data-mdb-input-init className="form-outline mb-2">
              <input
                type="Email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  backgroundColor: "#e8e8e8",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "30px",
                }}
                required
                placeholder="Enter Email"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-block mb-4"
              style={{
                backgroundColor: "#386bc0",
                textAlign: "center",
                color: "#000",
                width: "100%",
                padding: "10px",
                marginTop: "15px",
                borderRadius: "30px",
              }}
              disabled={loading} // Disable button when loading
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Send OTP"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
