import React, { useState } from "react";
import { Sidebar, Navbar } from "flowbite-react";
import {
  HiShoppingBag,
  HiUser,
  HiLockClosed,
  HiViewBoards,
  HiMenu,
  HiCreditCard,
  HiLogout,
} from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { FaMoneyBillWave, FaUserFriends } from "react-icons/fa";

export function Sidebarr() {
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useAuth();

  const toggleSidebar = () => {
    console.log(!isOpen);
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout(); // Call logout to clear authentication details
    history.push("/"); // Redirect to login page
  };

  const history = useHistory(); // Initialize useHistory
  return (
    <div style={{width: isOpen? "100%": '100%',position:'fixed',background:'#fff'}}>
      {/* Navbar for mobile view with hamburger menu */}
      <Navbar
        className="md:hidden"
        style={{
          backgroundColor: "#386bc0",
          padding: "10px",
          // zIndex: 9999,
        }}
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center">
            <img
              src="./assets/logo.png"
              style={{ width: "55px", height: "auto" }}
              alt="Logo"
            />
            {/* <h4 style={{ color: "#fff", margin: "0", marginLeft: "10px" }}>
              Social Dukan
            </h4> */}
          </div>
          <button onClick={toggleSidebar} style={{ color: "#386bc0" }}>
            <HiMenu size={24} />
          </button>
        </div>
      </Navbar>

      {/* Sidebar: Hidden in mobile view, shown in larger screens */}
      <div
        className={`fixed inset-0 z-40 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 transition-transform duration-200 ease-in-out md:flex`}
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          width: "250px",
          zIndex: isOpen ? "99999999999" : "-1",
        }}
      >
        <Sidebar
          aria-label="Default sidebar example"
          style={{ height: "100%" }}
        >
          <div
            style={{
              textAlign: "center",
              padding: "0px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              paddingTop: "20px",
            }}
          >
            <img
              src="./assets/logo.png"
              style={{ width: "55px", height: "auto" }}
              alt="Logo"
            />
            {/* <h4 style={{ color: "#386bc0", margin: "0", marginLeft: "10px" }}>
              Social Dukan
            </h4> */}
          </div>
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              <Sidebar.Item
                icon={HiViewBoards}
                onClick={() => {
                  history.push("/Home");
                }}
                style={{ textAlign: "left" }}
              >
                Dashboard
              </Sidebar.Item>
              {/* <Sidebar.Item
                icon={HiShoppingBag}
                onClick={() => {
                  history.push("/NewOrder");
                }}
                style={{ textAlign: "left" }}
              >
                New Order
              </Sidebar.Item> */}
              <Sidebar.Item
                icon={HiUser}
                onClick={() => {
                  history.push("/Profile");
                }}
                style={{ textAlign: "left" }}
              >
                Profile
              </Sidebar.Item>
              <Sidebar.Item
                icon={HiCreditCard}
                onClick={() => {
                  history.push("/Allotment");
                }}
                style={{ textAlign: "left" }}
              >
                Allotment
              </Sidebar.Item>
              {/* <Sidebar.Item
                icon={FaMoneyBillWave}
                onClick={() => {
                  history.push("/Refund");
                }}
                style={{ textAlign: "left" }}
              >
                Refund
              </Sidebar.Item> */}
              <Sidebar.Item
                icon={FaUserFriends}
                onClick={() => {
                  history.push("/Refer");
                }}
                style={{ textAlign: "left" }}
              >
                Refer
              </Sidebar.Item>
              <Sidebar.Item
                icon={HiLockClosed}
                onClick={() => {
                  history.push("/Password");
                }}
                style={{ textAlign: "left" }}
              >
                Password
              </Sidebar.Item>
              <Sidebar.Item
                icon={HiLogout}
                onClick={() => {
                  handleLogout();
                }}
                style={{ textAlign: "left" }}
              >
                Logout
              </Sidebar.Item>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </div>

      {/* Overlay for closing sidebar on mobile */}
      {isOpen && (
        <div
          className="fixed inset-0 z-30 bg-black opacity-50 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </div>
  );
}
