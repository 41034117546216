import React, { useState, useEffect } from "react";
import { Sidebarr } from "./Sidebar";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Cookies from 'js-cookie';
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";

export default function Refund() {
  const [reviewScreenshot, setReviewScreenshot] = useState(null);
  const [closedScreenshot, setClosedScreenshot] = useState(null);
  const [additionalScreenshot, setAdditionalScreenshot] = useState(null);
  const [reviewLink, setReviewLink] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [refundRequests, setRefundRequests] = useState([]);

  const fetchRefundRequests = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URI}/GetRefund/${Cookies.get('userId')}`);
      const data = await response.json();
      setRefundRequests(data);
    } catch (error) {
      console.error("Error fetching refund requests:", error);
    }
  };
  // Fetching refund requests from the endpoint
  useEffect(() => {

    fetchRefundRequests();
  }, []);

  const handleFileChange = (setter) => (event) => {
    const file = event.target.files[0];
    setter(file);
  };


  const cloudName = 'dsgmj6qd6'; // Your Cloudinary cloud name
  const uploadPreset = 'Social_Dukan'; // Your upload preset



  const uploadFileToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", uploadPreset); // Replace with your Cloudinary upload preset

    try {
      const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, { // Replace with your Cloudinary cloud name
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        return data.url; // Return the public ID of the uploaded file
      } else {
        throw new Error(data.error.message);
      }
    } catch (error) {
      toast.error("Failed to upload file to Cloudinary");
      console.log(error)
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!reviewScreenshot || !closedScreenshot || !additionalScreenshot) {
      toast.error('Please upload all required screenshots.');
      return;
    }
  
    setLoading(true);
  
    // Prepare the data to send
    const data = {
      UserId: Cookies.get('userId'), // Replace with dynamic UserId if needed
      ReviewLink: reviewLink,
      OrderId: Cookies.get('orderId'), // Replace with dynamic OrderId if needed
      RefundAmount: refundAmount,
      DeliveryDate: deliveryDate,
    };
  
    // Upload screenshots to Cloudinary and get the URLs
    const reviewScreenshotUrl = await uploadFileToCloudinary(reviewScreenshot);
    const closedScreenshotUrl = await uploadFileToCloudinary(closedScreenshot);
    const additionalScreenshotUrl = await uploadFileToCloudinary(additionalScreenshot);
  
      // Add screenshot URLs to the data object
      data.ReviewScreenshot = reviewScreenshotUrl;
      data.ClosedScreenshot = closedScreenshotUrl;
      data.AdditionalScreenshot = additionalScreenshotUrl;
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_URI}/RefundRequest`, data, {
          headers: {
            'Content-Type': 'application/json', // Set the content type to application/json
          },
        });
        console.log(response.data); // Log response data
        toast.success('Refund request submitted successfully!');
        setReviewLink('');
        setRefundAmount('');
        setDeliveryDate('');
        setAdditionalScreenshot(null);
        setReviewScreenshot(null);
        setClosedScreenshot(null);
        fetchRefundRequests()
      } catch (error) {
        console.error(error); // Log the error
        toast.error('An error occurred while submitting the refund request.');
      } finally {
        setLoading(false);
        
      }
  };
  

  return (
    <div>
      <div className="container">
        <div className="sidebar">
          <Sidebarr />
        </div>
        <div className="main-content">
          <h1 className="dashboard-title">Refund</h1>

          <div className="row" style={{ marginLeft: "0px", marginRight: "0px", marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "60px" }}>
            <div className="col-lg-6 col-md-6 mx-auto text-left">
              <form onSubmit={handleSubmit}>
                <div className="mb-2">
                  <label htmlFor="reviewScreenshot" style={{ fontWeight: "bold", display: "block", marginBottom: "5px" }}>
                    Review Screenshot
                  </label>
                  <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                    <input
                      type="file"
                      id="reviewScreenshot"
                      style={{ position: "absolute", opacity: 0, width: "100%", height: "100%", cursor: "pointer" }}
                      onChange={handleFileChange(setReviewScreenshot)}
                      required
                    />
                    <button
                      type="button"
                      style={{ backgroundColor: "#e8e8e8", color: "#000", width: "100%", padding: "10px", borderRadius: "30px", border: "1px solid #ccc", textAlign: "center", fontSize: "14px", fontWeight: "bold", cursor: "pointer", position: "relative", zIndex: 1, overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}
                      onClick={() => document.getElementById("reviewScreenshot").click()}
                    >
                      <i className="fas fa-upload" style={{ marginRight: "8px" }}></i>
                      {reviewScreenshot ? reviewScreenshot.name : "Choose File"}
                    </button>
                  </div>
                </div>

                <div className="mb-2">
                  <label htmlFor="closedScreenshot" style={{ fontWeight: "bold", display: "block", marginBottom: "5px" }}>
                    Return Window Closed Screenshot
                  </label>
                  <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                    <input
                      type="file"
                      id="closedScreenshot"
                      style={{ position: "absolute", opacity: 0, width: "100%", height: "100%", cursor: "pointer" }}
                      onChange={handleFileChange(setClosedScreenshot)}
                      required
                    />
                    <button
                      type="button"
                      style={{ backgroundColor: "#e8e8e8", color: "#000", width: "100%", padding: "10px", borderRadius: "30px", border: "1px solid #ccc", textAlign: "center", fontSize: "14px", fontWeight: "bold", cursor: "pointer", position: "relative", zIndex: 1, overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}
                      onClick={() => document.getElementById("closedScreenshot").click()}
                    >
                      <i className="fas fa-upload" style={{ marginRight: "8px" }}></i>
                      {closedScreenshot ? closedScreenshot.name : "Choose File"}
                    </button>
                  </div>
                </div>

                <div className="mb-2">
                  <input
                    type="text"
                    value={reviewLink}
                    onChange={(e) => setReviewLink(e.target.value)}
                    style={{ backgroundColor: "#e8e8e8", textAlign: "center", color: "#000", width: "100%", padding: "10px", marginTop: "5px", borderRadius: "30px" }}
                    required
                    placeholder="Review Link"
                  />
                </div>

                <div className="mb-2">
                  <label htmlFor="additionalScreenshot" style={{ fontWeight: "bold", display: "block", marginBottom: "5px" }}>
                    Additional Screenshot
                  </label>
                  <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                    <input
                      type="file"
                      id="additionalScreenshot"
                      style={{ position: "absolute", opacity: 0, width: "100%", height: "100%", cursor: "pointer" }}
                      onChange={handleFileChange(setAdditionalScreenshot)}
                      required
                    />
                    <button
                      type="button"
                      style={{ backgroundColor: "#e8e8e8", color: "#000", width: "100%", padding: "10px", borderRadius: "30px", border: "1px solid #ccc", textAlign: "center", fontSize: "14px", fontWeight: "bold", cursor: "pointer", position: "relative", zIndex: 1, overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}
                      onClick={() => document.getElementById("additionalScreenshot").click()}
                    >
                      <i className="fas fa-upload" style={{ marginRight: "8px" }}></i>
                      {additionalScreenshot ? additionalScreenshot.name : "Choose File"}
                    </button>
                  </div>
                </div>



                <div className="mb-2">
                  <input
                    type="text"
                    value={refundAmount}
                    onChange={(e) => setRefundAmount(e.target.value)}
                    style={{ backgroundColor: "#e8e8e8", textAlign: "center", color: "#000", width: "100%", padding: "10px", marginTop: "5px", borderRadius: "30px" }}
                    required
                    placeholder="Refund Amount"
                  />
                </div>

                <div className="mb-2">
                  <label htmlFor="deliveryDate" style={{ fontWeight: "bold" }}>
                    Delivery Date
                  </label>
                  <input
                    type="date"
                    value={deliveryDate}
                    onChange={(e) => setDeliveryDate(e.target.value)}
                    id="deliveryDate"
                    style={{ backgroundColor: "#e8e8e8", textAlign: "center", color: "#000", width: "100%", padding: "10px", marginTop: "5px", borderRadius: "30px", border: "none", fontSize: "16px" }}
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="btn-primary btn-block mb-4"
                  style={{ backgroundColor: "#386bc0", textAlign: "center", color: "#fff", width: "100%", padding: "10px", marginTop: "15px", borderRadius: "30px" }}
                >
                  {loading ? (<div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>) : "Save"}
                </button>
              </form>
            </div>

            <MDBTable align="middle" hover responsive>
          <MDBTableHead style={{ backgroundColor: "#386bc0", color: "#fff" }}>
            <tr>
              <th scope="col" style={{ color: '#fff' }}>#</th>
              <th scope="col" style={{ color: '#fff' }}>User</th>
              <th scope="col" style={{ color: '#fff' }}>Review Link</th>
              <th scope="col" style={{ color: '#fff' }}>Refund Amount</th>
              <th scope="col" style={{ color: '#fff' }}>Delivery Date</th>
              {/* <th scope="col" style={{ color: '#fff' }}>Review Screenshot</th> */}
              {/* <th scope="col" style={{ color: '#fff' }}>Window Screenshot</th> */}
              <th scope="col" style={{ color: '#fff' }}>Actions</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {refundRequests.map((request, index) => (
              <tr key={request.Id}>
                <td>{index + 1}</td>
                <td>
                  {request.Name} <br /> {request.Email}
                </td>
                <td>
                  <a href={request.ReviewLink} target="_blank" rel="noopener noreferrer">View Review</a>
                </td>
                <td>{request.RefundAmount}</td>
                <td>{new Date(request.CreatedAt).toLocaleDateString()}</td>
                <td>
                  <a href={request.ReviewScreenshot} target="_blank" rel="noopener noreferrer">
                    View Review Screenshot
                  </a>
                </td>
                <td>
                  <a href={request.ClosedScreenshot} target="_blank" rel="noopener noreferrer">
                    View Closed Screenshot
                  </a>
                </td>
                <td>
                 
                    {request.Status}
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>


          </div>
        </div>
      </div>
    </div>
  );
}
