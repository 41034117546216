import React, { useState,useEffect } from "react";
import { Sidebarr } from "./Sidebar";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import { useHistory } from "react-router-dom";
import CryptoJS from 'crypto-js';
import axios from "axios";

export default function NewOrder() {
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dealType, setDealType] = useState("");
  const [reviewerName, setReviewerName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [mediatorName, setMediatorName] = useState(Cookies.get('MediatorName'));
  const [orderId, setOrderId] = useState("");
  const [orderAmount, setOrderAmount] = useState("");
  const [orderScreenshot, setOrderScreenshot] = useState(null);
  const [AS, setAS] = useState("");

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const userId = Cookies.get("userId");

      try {
        const response = await fetch(`${process.env.REACT_APP_URI}/GetOrders/${userId}`);
        const result = await response.json();

        if (response.ok) {
          setOrders(result);
        } else {
          throw new Error("Failed to fetch orders");
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setOrderScreenshot(file);
    setAS(file.name);
  };


  // Function to handle form submission (file upload)
  const handleUpload = async (file) => {
    if (!file) {
      alert('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('file', file); // Append the file to formData

    try {
      // Send POST request to the backend API
      const response = await axios.post(`${process.env.REACT_APP_URI}/api/drive/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Check if the response contains an authorization URL
      if (response.data.authUrl) {
        // Redirect the user to the authorization URL
        window.location.href = response.data.authUrl;
      } else if (response.data && response.data.id) {
        console.log('File uploaded successfully. File ID:', response.data.id);
        alert(`File uploaded successfully. File ID: ${response.data.id}`);
      } else {
        console.error('Failed to get the file ID or authorization URL');
      }
    } catch (error) {
      // Handle error
      console.error('Error uploading file:', error);
      alert('Error uploading file. Please try again.');
    }
  };
  


  const cloudName = 'dsgmj6qd6'; // Your Cloudinary cloud name
  const uploadPreset = 'Social_Dukan'; // Your upload preset

  const uploadFileToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", uploadPreset); // Replace with your Cloudinary upload preset

    try {
      const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, { // Replace with your Cloudinary cloud name
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        return data.url; // Return the public ID of the uploaded file
      } else {
        throw new Error(data.error.message);
      }
    } catch (error) {
      toast.error("Failed to upload file to Cloudinary");
      console.log(error)
      return null;
    }
  };
  
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    
    const userId = Cookies.get("userId");
    const productCode = Cookies.get("ProductCode");
    const orderScreenshotId = orderScreenshot ? await uploadFileToCloudinary(orderScreenshot) : null;
    console.log(orderScreenshotId)

    const data = {
        UserId: userId,
        DealType: dealType,
        ReviewerName: reviewerName,
        Brand: brandName,
        Mediator: mediatorName,
        OrderId: orderId,
        OrderAmount: orderAmount,
        ProductCode: productCode,
        ProductName: Cookies.get('ProductName'),
        AllotmentId: Cookies.get('AllotmentId'),
        SS: orderScreenshotId
    };

    console.log("Data to be sent:", data); // Log the data

    try {
        const response = await fetch(`${process.env.REACT_APP_URI}/AddOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        
        const result = await response.json();
        
        if (response.ok) {
            toast.success(result.message);
            setAS('');
            setDealType('');
            setBrandName('');
            setReviewerName('');
            // setMediatorName('');
            setOrderAmount('');
            setOrderId(''); 
            setOrderScreenshot('');
        } else {
            throw new Error(result.message);
        }
    } catch (error) {
        console.error("Error during submission:", error); // Log the error
        toast.error("Failed to add order");
    } finally {
        setLoading(false);
    }
};

  


  return (
    <div className="container">
      <div className="sidebar">
        <Sidebarr />
      </div>

      <div className="main-content">
        <h1 className="dashboard-title" style={{zIndex:10}}>New Order</h1>

        <div
          className="row"
          style={{
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "60px",
            zIndex:10
          }}
        >
          <div className="col-lg-6 col-md-6 mx-auto text-left">
            <form onSubmit={handleSubmit}>
              <div
                className="info-card"
                style={{
                  backgroundColor: "#f4f4f4",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  marginBottom: "20px",
                }}
              >
                <h4 style={{ fontWeight: "bold" }}>Important Information</h4>
                <ul style={{ listStyleType: "decimal", paddingLeft: "20px" }}>
                  <li>Order should not have multi-brand products.</li>
                  <li>
                    In the Order Screenshot, the order date, order ID, order
                    amount, and all products should be clearly visible.
                  </li>
                  <li>Review must be posted only after 3 days of delivery.</li>
                  <li>
                    Positive-related review content with a 5-star rating and 3
                    product images is required.
                  </li>
                  <li>Review must be live until the date of payment.</li>
                </ul>
                <div style={{ marginTop: "10px" }}>
                  <input
                    type="checkbox"
                    id="agreeTerms"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    required
                  />
                  <label htmlFor="agreeTerms" style={{ marginLeft: "10px" }}>
                    I agree to the terms and conditions.
                  </label>
                </div>
              </div>

              {/* <div className="mb-2">
                <label className="form-label" style={{ fontWeight: "bold" }}>
                  Deal Type
                </label>
                <div className="radio-group">
                  <div>
                    <input
                      type="radio"
                      id="dealType1"
                      name="dealType"
                      value="Review"
                      checked={dealType === "Review"}
                      onChange={() => setDealType("Review")}
                      style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="dealType1">Review</label>
                  </div>
                  <div style={{ marginLeft: "15%" }}>
                    <input
                      type="radio"
                      id="dealType2"
                      name="dealType"
                      value="Rating"
                      checked={dealType === "Rating"}
                      onChange={() => setDealType("Rating")}
                      style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="dealType2">Rating</label>
                  </div>
                  <div style={{ marginLeft: "15%" }}>
                    <input
                      type="radio"
                      id="dealType3"
                      name="dealType"
                      value="Order"
                      checked={dealType === "Order"}
                      onChange={() => setDealType("Order")}
                      style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="dealType3">Only Order</label>
                  </div>
                </div>
              </div> */}

              <div className="mb-2">
                <input
                  type="text"
                  id="reviewerName"
                  value={reviewerName}
                  onChange={(e) => setReviewerName(e.target.value)}
                  style={{
                    backgroundColor: "#e8e8e8",
                    textAlign: "center",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    borderRadius: "30px",
                  }}
                  required
                  placeholder="Reviewer Name"
                />
              </div>

              {/* <div className="mb-2">
                <select
                  id="brandName"
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                  style={{
                    backgroundColor: "#e8e8e8",
                    color: "#000",
                    width: "100%",
                    padding: "13px",
                    borderRadius: "30px",
                  }}
                  required
                >
                  <option value="">Select Brand</option>
                  <option value="Mivi - Flipkart">Mivi - Flipkart</option>
                  <option value="Peesafe - Flipkart">Peesafe - Flipkart</option>
                  <option value="Sugar pop - Amazon">Sugar pop - Amazon</option>
                </select>
              </div> */}

              <div className="mb-2">
                <input
                  type="text"
                  id="orderId"
                  value={mediatorName}
                  onChange={(e) => setMediatorName(e.target.value)}
                  style={{
                    backgroundColor: "#e8e8e8",
                    textAlign: "center",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    borderRadius: "30px",
                  }}
                  required
                  placeholder="Mediator Name"
                  readOnly
                />
              </div>

              <div className="mb-2">
                <input
                  type="text"
                  id="orderId"
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                  style={{
                    backgroundColor: "#e8e8e8",
                    textAlign: "center",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    borderRadius: "30px",
                  }}
                  required
                  placeholder="Order Id"
                />
              </div>

              <div className="mb-2">
                <label htmlFor="orderScreenshot" style={{ fontWeight: "bold", display: "block", marginBottom: "5px" }}>
                  Order Screenshot
                </label>
                <label
                  htmlFor="orderScreenshot"
                  className="custom-file-upload"
                  style={{
                    fontWeight: "bold",
                    display: "block",
                    marginBottom: "5px",
                    backgroundColor: "#e8e8e8",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "30px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <i className="fas fa-upload" style={{ marginRight: "8px" }}></i>
                  {AS || "Choose File"}
                </label>
                <input
                  type="file"
                  id="orderScreenshot"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  required
                />
              </div>

              <div className="mb-3">
                <input
                  type="text"
                  id="orderAmount"
                  value={orderAmount}
                  onChange={(e) => setOrderAmount(e.target.value)}
                  style={{
                    backgroundColor: "#e8e8e8",
                    textAlign: "center",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "30px",
                  }}
                  required
                  placeholder="Order Amount"
                />
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn-primary btn-block mb-4"
                  style={{
                    backgroundColor: "#386bc0",
                    textAlign: "center",
                    color: "#fff",
                    width: "100%",
                    padding: "10px",
                    marginTop: "15px",
                    borderRadius: "30px",
                    position: "relative",
                  }}
                >
                  {loading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>


          {/* <MDBTable align="middle" hover responsive className="mt-4">
          <MDBTableHead style={{ backgroundColor: "#386bc0", color: "#fff" }}>
            <tr>
              <th scope="col" style={{ color: "#fff", minWidth: "50px" }}>#</th>
              <th scope="col" style={{ color: "#fff", minWidth: "100px" }}>Deal Type</th>
              <th scope="col" style={{ color: "#fff", minWidth: "150px" }}>Reviewer Name</th>
              <th scope="col" style={{ color: "#fff", minWidth: "150px" }}>Brand</th>
              <th scope="col" style={{ color: "#fff", minWidth: "150px" }}>Mediator</th>
              <th scope="col" style={{ color: "#fff", minWidth: "150px" }}>Order ID</th>
              <th scope="col" style={{ color: "#fff", minWidth: "150px" }}>Order Amount</th>
              <th scope="col" style={{ color: "#fff", minWidth: "150px" }}>Status</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {loading ? (
              <tr>
                <td colSpan="8" className="text-center">Loading...</td>
              </tr>
            ) : orders.length > 0 ? (
              orders.map((order, index) => (
                <tr key={order.Id} onClick={()=>{if(order.OrderStatus === 'Approved'){history.push('/Refund')}}}>
                  <td>{index + 1}</td>
                  <td>{order.DealType}</td>
                  <td>{order.ReviewerName}</td>
                  <td>{order.Brand}</td>
                  <td>{order.Mediator}</td>
                  <td>{order.OrderId}</td>
                  <td>{order.OrderAmount}</td>
                  <td>{order.OrderStatus}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center">No orders found</td>
              </tr>
            )}
          </MDBTableBody>
        </MDBTable> */}

        </div>
      </div>
      <Toaster />
    </div>
  );
}
