import React, { useState, useEffect } from "react";
import { Sidebarr } from "./Sidebar";
import {
  MDBBadge,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit"; // Assuming you add the CSS here
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";
import axios from "axios"; // Make sure axios is imported
// src/Components/Home.js
import { useAuth } from '../AuthContext';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const Home = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({
    pendingOrders: 0,
    rejectedOrders: 0,
    canceledOrders: 0,
    paidOrders: 0,
  });
  const [orders, setOrders] = useState([]);
  const [refundData, setRefundData] = useState({}); // State to hold product codes and their refund days

  const { login } = useAuth();
    const location = useLocation();

    useEffect(() => {
        const { client_e, client_Id, mainid } = queryString.parse(location.search);

        // If the parameters are present, decode and log in the user
        if (client_e && client_Id && mainid) {
            const decodedEmail = Buffer.from(client_e, 'base64').toString('utf-8');
            const decodedUserId = Buffer.from(client_Id, 'base64').toString('utf-8');

            // Call the login function to set cookies
            login('your-auth-token', decodedUserId, decodedEmail); // You should replace 'your-auth-token' with the actual token if available
        }
    }, [location.search, login]);

  useEffect(() => {
    const fetchSpreadsheetData = () => {
      const range = `${sheetName}!B:J`; // Adjust range to fetch both Product Codes and Refund Days
      axios
        .get(
          `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${API_KEY}`
        )
        .then((response) => {
          const data = response.data.values;
          if (data && data.length > 1) {
            const refundMap = {};
            data.slice(1).forEach((row) => {
              const productCode = row[0]?.trim(); // Product Code in B column
              const refundDays = row[8]; // Refund Days in J column
              if (productCode && refundDays) {
                refundMap[productCode] = parseInt(refundDays, 10);
              }
            });
            setRefundData(refundMap);
          }
        })
        .catch((error) => {
          console.error("Error fetching spreadsheet data: ", error);
        });
    };

    fetchSpreadsheetData();
  }, []);

  useEffect(() => {
   

    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    setLoading(true);
    const userId = Cookies.get("userId");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URI}/GetOrders/${userId}`
      );
      const result = await response.json();

      if (response.ok) {
        setOrders(result);
      } else {
        throw new Error("Failed to fetch orders");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Update stats based on orders
  useEffect(() => {
    if (orders.length > 0) {
      const rejectedOrders = orders.filter(
        (order) => order.OrderStatus === "Rejected"
      ).length;
      const acceptedOrders = orders.filter(
        (order) => order.OrderStatus === "Approved"
      ).length;
      const pendingOrders = orders.filter(
        (order) => order.OrderStatus === "Pending"
      ).length;
      const canceledOrders = orders.filter(
        (order) => order.OrderStatus === "Canceled"
      ).length;

      setStats({
        pendingOrders,
        rejectedOrders,
        canceledOrders,
        paidOrders: acceptedOrders,
      });
    }
  }, [orders]);

  const CLIENT_ID =
    "250205346153-6n6hi5ap7hqqirc47h7fbjub9v268qml.apps.googleusercontent.com"; // Replace with your OAuth 2.0 Client ID
  const API_KEY = "AIzaSyCQIfD6Xn4shvGu-lJ3Bi291tv5dP2hado"; // Replace with your API Key
  const SCOPES = "https://www.googleapis.com/auth/spreadsheets.readonly"; // Scope for reading spreadsheets
  const spreadsheetId = "1qYao-lLEaoiOwtHUyKHxrpNIuUVs0w2nUg-faXMd36Q"; // Your Spreadsheet ID
  const sheetName = "Sheet2"; // Your Sheet Name

  // Function to calculate remaining days to refund
  const calculateRemainingDays = (createdAt, refundDays) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const differenceInTime = currentDate - createdDate; // Difference in milliseconds
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert to days
    return refundDays - differenceInDays; // Remaining days
  };

  return (
    <div className="container">
      <div className="sidebar">
        <Sidebarr />
      </div>
      <div className="main-content">
        <h1 className="dashboard-title">Dashboard</h1>
        {/* Cards displaying stats */}
        <div className="c">
          <div
            md="6"
            lg="3"
            className="d-flex col"
            style={{ marginTop: "10px" }}
          >
            <div
              className="flex-grow-1"
              style={{ backgroundColor: "#e6f7ff" ,borderRadius:'10px',padding:'20px',margin:'0px 10px'}}
            >
              {" "}
              {/* Light green background */}
              <MDBCardBody className="d-flex flex-column align-items-center justify-content-center">
                <MDBCardTitle>Pending Orders</MDBCardTitle>
                <MDBCardText>{stats.pendingOrders}</MDBCardText>
              </MDBCardBody>
            </div>
          </div>
          <div
            md="6"
            lg="3"
            className="d-flex col"
            style={{ marginTop: "10px" }}
          >
            <div
              className="flex-grow-1"
              style={{ backgroundColor: "#e6ffe6" ,borderRadius:'10px',padding:'20px',margin:'0px 10px'}}
            >
              {" "}
              {/* Light green background */}
              <MDBCardBody className="d-flex flex-column align-items-center justify-content-center">
                <MDBCardTitle>Rejected Orders</MDBCardTitle>
                <MDBCardText>{stats.rejectedOrders}</MDBCardText>
              </MDBCardBody>
            </div>
          </div>
          <div
            md="6"
            lg="3"
            className="d-flex col"
            style={{ marginTop: "10px" }}
          >
            <div
              className="flex-grow-1"
              style={{ backgroundColor: "#fff4e6" ,borderRadius:'10px',padding:'20px',margin:'0px 10px'}}
            >
              {" "}
              {/* Light orange background */}
              <MDBCardBody className="d-flex flex-column align-items-center justify-content-center">
                <MDBCardTitle>Canceled Orders</MDBCardTitle>
                <MDBCardText>{stats.canceledOrders}</MDBCardText>
              </MDBCardBody>
            </div>
          </div>
          <div
            md="6"
            lg="3"
            className="d-flex col"
            style={{ marginTop: "10px" }}
          >
            <div
              className="flex-grow-1"
              style={{ backgroundColor: "#ffe6f7",borderRadius:'10px',padding:'20px' ,margin:'0px 10px'}}
            >
              {" "}
              {/* Light pink background */}
              <MDBCardBody className="d-flex flex-column align-items-center justify-content-center">
                <MDBCardTitle>Paid Orders</MDBCardTitle>
                <MDBCardText>{stats.paidOrders}</MDBCardText>
              </MDBCardBody>
            </div>
          </div>
        </div>
        {/* Orders Table */}
        <div style={{ width: "100%", overflow: "hidden" }}>
  <MDBTable
    align="middle"
    hover
    responsive
    className="mt-4 table-responsive-custom"
  >
    <MDBTableHead style={{ backgroundColor: "#386bc0", color: "#fff" }}>
      <tr>
        <th scope="col" style={{ color: "#fff" }}>#</th>
        <th scope="col" style={{ color: "#fff" }}>Reviewer Name</th>
        <th scope="col" style={{ color: "#fff" }}>Product Code</th>
        <th scope="col" style={{ color: "#fff" }}>Product Name</th>
        <th scope="col" style={{ color: "#fff" }}>Mediator</th>
        <th scope="col" style={{ color: "#fff" }}>Order ID</th>
        <th scope="col" style={{ color: "#fff" }}>Order Amount</th>
        <th scope="col" style={{ color: "#fff" }}>Status</th>
        <th scope="col" style={{ color: "#fff" }}>Remaining Refund Days</th>
        <th scope="col" style={{ color: "#fff" }}>Action</th>
      </tr>
    </MDBTableHead>
    <MDBTableBody>
      {loading ? (
        <tr>
          <td colSpan="10" className="text-center">Loading...</td>
        </tr>
      ) : orders.length > 0 ? (
        orders.map((order, index) => {
          const refundDays = refundData[order.ProductCode] || 0;
          const remainingDays =
            order.OrderStatus === "Approved"
              ? calculateRemainingDays(order.CreatedAt, refundDays)
              : "N/A";

          return (
            <tr key={order.Id}>
              <td>{index + 1}</td>
              <td>{order.ReviewerName}</td>
              <td>{order.ProductCode}</td>
              <td>{order.ProductName}</td>
              <td>{order.Mediator}</td>
              <td>{order.OrderId}</td>
              <td>{order.OrderAmount}</td>
              <td>{order.OrderStatus}</td>
              <td>
                {typeof remainingDays === "number"
                  ? remainingDays < 0
                    ? "Refund period has passed."
                    : `${remainingDays} days remaining`
                  : remainingDays}
              </td>
              <td>
                {typeof remainingDays === "number" && remainingDays >= 0 ? (
                  <button
                    style={{
                      background: "#386bc0",
                      borderRadius: "5px",
                      color: "#fff",
                      padding: "5px 10px",
                    }}
                    onClick={() => {
                      if (order.OrderStatus === "Approved") {
                        Cookies.set("orderId", order.Id);
                        history.push("/Refund");
                      }
                    }}
                  >
                    Refund
                  </button>
                ) : null}
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan="10" className="text-center">No orders found</td>
        </tr>
      )}
    </MDBTableBody>
  </MDBTable>
</div>

      </div>
    </div>
  );
};
