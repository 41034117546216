import React,{useState} from 'react'
import { Sidebarr } from './Sidebar'

export default function Update() {
  const [AS, setAS] = useState("");

  const handleAS = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAS(file.name);
    } else {
      setAS("");
    }
  };
  return (
    <div className="container">
      {/* Sidebar with a fixed width */}
      <div className="sidebar">
        <Sidebarr />
      </div>

      {/* Main content */}
      <div className="main-content">
        <h1 className="dashboard-title">Update</h1>

        <div
          className="row"
          style={{
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "60px",
          }}
        >
          <div className="col-lg-6 col-md-6 mx-auto text-left">
            <form>
              {/* Deal Type Radio Buttons */}
              <div className=" mb-2">
                <label className="form-label" style={{fontWeight:'bold'}}>Deal Type</label>
                <div className="radio-group">
                  <div>
                    <input
                      type="radio"
                      id="dealType1"
                      name="dealType"
                      value="Review"
                      style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="dealType1">Review</label>
                  </div>
                  <div style={{marginLeft:'15%'}}>
                    <input
                      type="radio"
                      id="dealType2"
                      name="dealType"
                      value="Rating"
                      style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="dealType2">Rating</label>
                  </div>
                  <div style={{marginLeft:'15%'}}>
                    <input
                      type="radio"
                      id="dealType3"
                      name="dealType"
                      value="Order"
                      style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="dealType3">Order</label>
                  </div>
                </div>
              </div>

              {/* Reviewer Name Input */}
              <div className=" mb-2">
                <input
                  type="text"
                  id="reviewerName"
                  style={{
                    backgroundColor: "#e8e8e8",
                    textAlign: "center",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    borderRadius: "30px",
                  }}
                  required
                  placeholder="Reviewer Name"
                />
              </div>

              {/* Brand Name Dropdown */}
              <div className=" mb-2">
                <select
                  id="brandName"
                  style={{
                    backgroundColor: "#e8e8e8",
                    color: "#000",
                    width: "100%",
                    padding: "13px",
                    borderRadius: "30px",
                  }}
                  required
                >
                  <option value="">Select Brand</option>
                  <option value="Mivi - Flipkart">Mivi - Flipkart</option>
                  <option value="Peesafe - Flipkart">Peesafe - Flipkart</option>
                  <option value="Sugar pop - Amazon">Sugar pop - Amazon</option>
                </select>
              </div>

              {/* Mediator Name Dropdown */}
              <div className=" mb-2">
                <select
                  id="mediatorName"
                  style={{
                    backgroundColor: "#e8e8e8",
                    color: "#000",
                    width: "100%",
                    padding: "13px",
                    borderRadius: "30px",
                  }}
                  required
                >
                  <option value="">Select Mediator</option>
                  <option value="Mansi Gupta">Mansi Gupta</option>
                  <option value="MM-Deals">MM-Deals</option>
                  <option value="Mohammad">Mohammad</option>
                  <option value="REVIEW-and-REFUND">REVIEW-and-REFUND</option>
                  <option value="Shivani">Shivani</option>
                  <option value="Social Dukan">Social Dukan</option>
                  <option value="Vinita">Vinita</option>
                  <option value="Vinny">Vinny</option>
                </select>
              </div>

              {/* Order Id Input */}
              <div className=" mb-2">
                <input
                  type="text"
                  id="orderId"
                  style={{
                    backgroundColor: "#e8e8e8",
                    textAlign: "center",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    borderRadius: "30px",
                  }}
                  required
                  placeholder="Order Id"
                />
              </div>

              <div className="mb-2">
                  <label
                    htmlFor="orderScreenshot"
                    style={{
                      fontWeight: "bold",
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    Order Screenshot
                  </label>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <input
                      type="file"
                      id="orderScreenshot"
                      style={{
                        position: "absolute",
                        opacity: 0,
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onChange={handleAS}
                      required
                    />
                    <button
                      style={{
                        backgroundColor: "#e8e8e8",
                        color: "#000",
                        width: "100%",
                        padding: "10px",
                        borderRadius: "30px",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        position: "relative",
                        zIndex: 1,
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() =>
                        document.getElementById("orderScreenshot").click()
                      }
                    >
                      <i
                        className="fas fa-upload"
                        style={{ marginRight: "8px" }}
                      ></i>
                      {AS || "Choose File"}
                    </button>
                  </div>
                </div>

              {/* Order Amount Input */}
              <div className=" mb-4">
                <input
                  type="text"
                  id="orderAmount"
                  style={{
                    backgroundColor: "#e8e8e8",
                    textAlign: "center",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    borderRadius: "30px",
                  }}
                  required
                  placeholder="Order Amount"
                />
              </div>

              {/* Save Button */}
              <button
                type="submit"
                className=" btn-primary btn-block mb-4"
                style={{
                  backgroundColor: "#386bc0",
                  textAlign: "center",
                  color: "#fff",
                  width: "100%",
                  padding: "10px",
                  marginTop: "15px",
                  borderRadius: "30px",
                }}
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
