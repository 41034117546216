import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { useAuth } from "../AuthContext";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google"; // Import GoogleLogin

const Login = () => {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { login } = useAuth();

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_URI}/Login`, {
        Email,
        Password,
        rememberMe,
      });

      const { data } = response;

      if (data.message === "Login successful") {
        login(data.token, data.id, data.Email);
        history.push("/Home");
        toast.success("Login successful!");
      } else {
        toast.error("Invalid credentials");
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Invalid credentials");
    }
  };

  const handleGoogleSuccess = async (response) => {
    console.log("Google login response:", response); // Check full response

    try {
      const tokenId = response.credential; // Extract the tokenId
      console.log("Google login tokenId:", tokenId); // Check if tokenId is correct

      const res = await axios.post(
        `${process.env.REACT_APP_URI}/google-login`,
        {
          tokenId,
        }
      );

      console.log("Backend response:", res.data);

      const { data } = res;

      if (data.message === "Login successful") {
        login(data.token, data.id, data.Email);
        history.push("/Home");
        toast.success("Google login successful!");
      } else {
        toast.error("Google login failed");
      }
    } catch (error) {
      console.error("Google login error:", error);
      toast.error("Google login failed");
    }
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleGoogleSuccess,
    });

    google.accounts.id.renderButton(
      document.getElementById("googleSignInDiv"),
      { theme: "outline", size: "large" }
    );
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      {console.log("Google Client ID:", process.env.REACT_APP_GOOGLE_CLIENT_ID)}
      <div>
        <Toaster />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "80px",
          }}
        >
          <img
            src="./assets/logo.png"
            style={{ width: "70px", height: "auto" }}
            alt=""
          />
        </div>

        <h1 style={{ color: "#386bc0", marginTop: "20px" }}> Login</h1>
        <div
          className="row"
          style={{
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "60px",
          }}
        >
          <form
            action="#"
            className="contact_form"
            id="contact_form"
            onSubmit={handleSubmit}
          >
            <div className="col-lg-3 col-md-6 mx-auto text-left">
              <div data-mdb-input-init className="form-outline mb-2">
                <input
                  type="Email"
                  id="form2Example1"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    backgroundColor: "#e8e8e8",
                    textAlign: "center",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    borderRadius: "30px",
                  }}
                  required
                  placeholder="Enter Email"
                />
              </div>

              <div data-mdb-input-init className="form-outline mb-4">
                <input
                  type="Password"
                  id="form2Example2"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{
                    backgroundColor: "#e8e8e8",
                    textAlign: "center",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    borderRadius: "30px",
                  }}
                  placeholder="Enter Password"
                />
              </div>

              <div className="row mb-4">
                <div className="col d-flex justify-content-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="form2Example31"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="form2Example31"
                    >
                      Remember me
                    </label>
                  </div>
                </div>

                <div className="col">
                  <Link to="/ForgotPassword" style={{ color: "#386bc0" }}>
                    Forgot Password?
                  </Link>
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary btn-block mb-4"
                style={{
                  backgroundColor: "#386bc0",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "15px",
                  borderRadius: "30px",
                }}
              >
                Sign in
              </button>

              <div className="text-center">
                <p>
                  Not a member?{" "}
                  <Link to="/SignUp" style={{ color: "#386bc0" }}>
                    Register
                  </Link>
                </p>
                <p>or sign up with:</p>

                <button
                  type="button"
                  className="btn btn-primary btn-block mb-4"
                  style={{
                    backgroundColor: "#db4437", // Google's red color
                    textAlign: "center",
                    color: "#fff",
                    width: "100%", // Full width button
                    padding: "10px",
                    marginTop: "15px",
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    window.location.href = `${process.env.REACT_APP_URI}/auth/google`; // Redirect to Google OAuth
                  }}
                >
                  <i
                    className="fab fa-google"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Continue with Google
                </button>

                {/* Google Login Button */}
                {/* <GoogleLogin
  onSuccess={handleGoogleSuccess}
  onError={(error) => {
    console.error("Google login error:", error);
    toast.error("Google login failed");
  }}
  ux_mode="popup" // Opens in a popup window instead of redirect
  prompt="select_account" // Forces account selection
/> */}

                {/* <button
                  type="button"
                  className="btn btn-link btn-floating mx-1"
                >
                  <i className="fab fa-facebook-f"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-link btn-floating mx-1"
                >
                  <i className="fab fa-twitter"></i>
                </button>

                <button
                  type="button"
                  className="btn btn-link btn-floating mx-1"
                >
                  <i className="fab fa-github"></i>
                </button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
