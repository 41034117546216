import React, { useEffect, useState } from "react";
import { Sidebarr } from "./Sidebar";
import { Modal, Button, Form } from "react-bootstrap";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import Cookies from "js-cookie"; // Import js-cookie
import { toast, Toaster } from "react-hot-toast";

export default function Refer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Extract user ID from cookies
  const userId = Cookies.get("userId"); // Replace 'userId' with the actual key you use
  const referralLink = `${process.env.REACT_APP_URL}/SignUp?id=${userId}`;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        toast.success("Referral link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const [referralData, setReferralData] = useState([]);
  const fetchReferralData = async () => {
    // Fetch orders for the selected referral
    try {
      const ordersResponse = await fetch(
        `${process.env.REACT_APP_URI}/Admin/GetOrdersForReferral/${Cookies.get(
          "userId"
        )}`
      );
      if (!ordersResponse.ok) {
        throw new Error("Network response was not ok");
      }
      const ordersData = await ordersResponse.json();
      console.log(ordersData);
      setReferralData(ordersData); // Set fetched orders
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchReferralData();
  }, []);
  return (
    <div>
      <Toaster />
      <div className="container">
        {/* Sidebar with a fixed width */}
        <div className="sidebar">
          <Sidebarr />
        </div>

        {/* Main content */}
        <div className="main-content">
          <div
            style={{
              backgroundColor: "#fff",
              padding: "30px",
              borderRadius: "15px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              maxWidth: "600px",
              margin: "50px auto",
            }}
          >
            <h1
              className="dashboard-title1"
              style={{
                fontWeight: "600",
                fontSize: "2rem",
                color: "#333",
                marginBottom: "15px",
              }}
            >
              Earn Rewards by Referring Your Friends
            </h1>
            <h5
              style={{
                fontWeight: "400",
                fontSize: "1.1rem",
                color: "#666",
                marginBottom: "25px",
              }}
            >
              Invite your friends and earn exciting rewards when they sign up.
            </h5>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <input
                type="text"
                value={referralLink}
                readOnly
                style={{
                  width: "100%",
                  padding: "12px 50px 12px 12px", // Add padding to create space for the button
                  borderRadius: "30px",
                  border: "1px solid #ccc",
                  borderRight: "0px solid #ccc",
                }}
              />
              <button
                onClick={copyToClipboard}
                style={{
                  marginLeft: "-50px",
                  backgroundColor: "#386bc0",
                  color: "#fff",
                  padding: "12px 20px",
                  borderRadius: "30px",
                  border: "none",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  fontSize: "1.1rem",
                  fontWeight: "500",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#2d5a9e")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#386bc0")
                }
              >
                Copy
              </button>
            </div>
          </div>
          {/* Referral Steps */}
          <div className="referral-steps" style={{ marginTop: "40px" }}>
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    }}
  >
    <div
      style={{
        textAlign: "center",
        padding: "20px",
        borderRadius: "15px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/128/8867/8867582.png"
          alt="refer"
          style={{
            marginBottom: "15px",
            width: "25px",
            height: "auto",
          }}
        />
        <h5
          style={{
            fontWeight: "600",
            color: "#333",
            marginLeft: "10px",
            fontSize: "1.1rem",
          }}
        >
          Refer Your Friends
        </h5>
      </div>
      <p style={{ color: "#666" }}>
        Share your unique referral link with your friends and family to earn
        rewards.
      </p>
    </div>

    <div
      style={{
        textAlign: "center",
        padding: "20px",
        borderRadius: "15px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/128/1435/1435715.png"
          alt="refer"
          style={{
            marginBottom: "15px",
            width: "25px",
            height: "auto",
          }}
        />
        <h5
          style={{
            fontWeight: "600",
            color: "#333",
            marginLeft: "10px",
            fontSize: "1.1rem",
          }}
        >
          Earn Rewards
        </h5>
      </div>
      <p style={{ color: "#666" }}>
        Earn exciting rewards when your friends sign up using your referral
        link.
      </p>
    </div>

    <div
      style={{
        textAlign: "center",
        padding: "20px",
        borderRadius: "15px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/128/4217/4217979.png"
          alt="refer"
          style={{
            marginBottom: "15px",
            width: "30px",
            height: "auto",
          }}
        />
        <h5
          style={{
            fontWeight: "600",
            color: "#333",
            marginLeft: "10px",
            fontSize: "1.1rem",
          }}
        >
          Track Your Progress
        </h5>
      </div>
      <p style={{ color: "#666" }}>
        Monitor your referrals and earnings in your dashboard.
      </p>
    </div>
  </div>
</div>

<style jsx>{`
.referral-steps > div > div {
      width: 30%;
    }
  @media (max-width: 768px) {
    .referral-steps > div {
      flex-direction: column;
      width: 100%;
    }

    .referral-steps > div > div {
      width: 100%;
    }
  }
`}</style>


          {/* Table to display referral data */}
          <div style={{ marginTop: "50px" }}>
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              Your Referrals
            </h2>
            <MDBTable align="middle" hover responsive>
              <MDBTableHead
                style={{ backgroundColor: "#386bc0", color: "#fff" }}
              >
                <tr>
                  <th scope="col" style={{ color: "#fff" }}>
                    #
                  </th>
                  <th scope="col" style={{ color: "#fff" }}>
                    Order Id
                  </th>
                  <th scope="col" style={{ color: "#fff" }}>
                    Brand
                  </th>
                  <th scope="col" style={{ color: "#fff" }}>
                    Order Amount
                  </th>
                  <th scope="col" style={{ color: "#fff" }}>
                    Created At
                  </th>
                  {/* <th scope="col" style={{ color: "#fff" }}>Action</th> */}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {referralData.map((order, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{order.OrderId}</td>
                    <td>{order.Brand}</td>
                    <td>{order.OrderAmount}</td>
                    <td>{new Date(order.CreatedAt).toLocaleString()}</td>
                    {/* <td>
                      <MDBBtn
                        color="primary"
                        onClick={() => handleMarkAsPaid(order.OrderId)}
                      >
                        Mark as Paid
                      </MDBBtn>
                    </td> */}
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="custom-modal"
        backdropClassName="modal-backdrop"
      >
        <div
          style={{
            background: "#fff",
            borderRadius: "15px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            margin: "0 auto",
            maxWidth: "500px",
          }}
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title style={{ fontWeight: "600", fontSize: "1.5rem" }}>
              Refer Your Friends
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ marginBottom: "20px", fontSize: "1rem" }}>
              Fill out the form below to refer your friends and earn rewards.
            </p>
            <Form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    backgroundColor: "#f9f9f9",
                    textAlign: "left",
                    color: "#333",
                    padding: "12px 15px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  placeholder="Your Name"
                  required
                />
              </div>

              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  style={{
                    backgroundColor: "#f9f9f9",
                    textAlign: "left",
                    color: "#333",
                    padding: "12px 15px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  placeholder="Your Email"
                  required
                />
              </div>

              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    backgroundColor: "#f9f9f9",
                    textAlign: "left",
                    color: "#333",
                    padding: "12px 15px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  placeholder="Friend's Name"
                  required
                />
              </div>

              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  style={{
                    backgroundColor: "#f9f9f9",
                    textAlign: "left",
                    color: "#333",
                    padding: "12px 15px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  placeholder="Friend's Email"
                  required
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none", justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              style={{
                backgroundColor: "#386bc0",
                color: "#fff",
                width: "100%",
                padding: "12px",
                borderRadius: "30px",
                fontSize: "1rem",
                fontWeight: "500",
                border: "none",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s ease",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#2d5a9e")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#386bc0")}
            >
              Refer Now
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
}
