import React, { useState, useEffect } from "react";
import { Sidebarr } from "./Sidebar";
import { gapi } from "gapi-script";
import axios from "axios";
import Cookies from "js-cookie";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit"; // Import MDB components
import toast, { Toaster } from "react-hot-toast"; // Import React Hot Toast
import { Spinner } from "react-bootstrap"; // Import Spinner from Bootstrap
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "flowbite-react";

export default function Allotment() {
  const history = useHistory(); // Initialize useHistory
  const [productCode, setProductCode] = useState([]);
  const [productCodes, setProductCodes] = useState([]);
  const [mediatorNames, setMediatorNames] = useState([]);
  const [mediatorName, setMediatorName] = useState("");
  const [productName, setProductName] = useState("");
  const [productNames, setProductNames] = useState([]);
  const [spreadsheetData, setSpreadsheetData] = useState([]);
  const [allotments, setAllotments] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state
  const [profileData, setProfileData] = useState(null);

  const CLIENT_ID =
    "250205346153-6n6hi5ap7hqqirc47h7fbjub9v268qml.apps.googleusercontent.com"; // Replace with your OAuth 2.0 Client ID
  const API_KEY = "AIzaSyCQIfD6Xn4shvGu-lJ3Bi291tv5dP2hado"; // Replace with your API Key
  const SCOPES = "https://www.googleapis.com/auth/spreadsheets.readonly"; // Scope for reading spreadsheets
  const spreadsheetId = "1qYao-lLEaoiOwtHUyKHxrpNIuUVs0w2nUg-faXMd36Q"; // Your Spreadsheet ID
  const sheetName = "Sheet2"; // Your Sheet Name

  // Fetch profile data using ID from cookies
  useEffect(() => {
    const userId = Cookies.get("userId"); // Get user ID from cookies

    if (userId) {
      // Fetch the profile data using the ID
      fetch(`${process.env.REACT_APP_URI}/GetProfile/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setProfileData(data); // Set the profile data
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    } else {
      history.push("/"); // Redirect to login if no user ID is found in cookies
    }
  }, [history]);

  // Fetch spreadsheet data without authentication
  const fetchSpreadsheetData = () => {
    const range = `${sheetName}!A1:Z`; // Adjust range as necessary
    axios
      .get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${API_KEY}`
      )
      .then((response) => {
        const data = response.data.values;
        setSpreadsheetData(data);
        if (data && data.length > 1) {
          const codes = data
            .slice(1)
            .map((row) => row[1]?.trim())
            .filter((code) => code); // Get product codes from the second column, remove whitespace, and filter out empty strings
          setProductCodes(codes); // Set product codes state
          const names = data
            .slice(2)
            .map((row) => row[16]?.trim())
            .filter((code) => code); // Get product codes from the second column, remove whitespace, and filter out empty strings
          const products = data
            .slice(2)
            .map((row) => row[2]?.trim())
            .filter((code) => code); // Get product codes from the second column, remove whitespace, and filter out empty strings
          setMediatorNames(names); // Set product codes state
          setProductCode(codes[0]);
          setMediatorName(names[0]);
          setProductNames(products);
          setProductName(products[0]);
          console.log(names);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  useEffect(() => {
    fetchSpreadsheetData(); // Fetch data on component mount

    // Fetch allotments data
    axios
      .get(
        `${process.env.REACT_APP_URI}/GetAllotments/${Cookies.get("userId")}`
      )
      .then((response) => {
        setAllotments(response.data.userResults);
        console.log("Allotments Data:", response.data.userResults);
      })
      .catch((error) => {
        console.error("Error fetching allotments: ", error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (profileData.PhoneNumber !== null) {
      setIsSubmitting(true);
      const allotmentData = {
        UserId: Cookies.get("userId"),
        ProductCode: productCode,
        MediatorName: mediatorName,
        ProductName: productName,
      };

      axios
        .post(`${process.env.REACT_APP_URI}/CreateAllotment`, allotmentData)
        .then((response) => {
          toast.success("Allotment created successfully!");
          console.log("Allotment created:", response.data);
          setProductCode("");
          setMediatorName("");
          return axios.get(
            `${process.env.REACT_APP_URI}/GetAllotments/${Cookies.get(
              "userId"
            )}`
          );
        })
        .then((response) => {
          setAllotments(response.data.userResults);
        })
        .catch((error) => {
          toast.error("Error creating allotment.");
          console.error("Error creating allotment: ", error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      toast.error("Please update your profile or add number to proceed");
    }
  };
  return (
    <div>
      <div className="container">
        <div className="sidebar">
          <Sidebarr />
        </div>
        <div className="main-content">
          <h1 className="dashboard-title">Allotment</h1>

          <div
            className="row"
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "60px",
            }}
          >
            <div className="col-lg-6 col-md-6 mx-auto text-left">
              <form onSubmit={handleSubmit}>
                <div className="mb-2">
                  <select
                    id="productCode"
                    value={productCode}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    onChange={(e) => {
                      setProductCode(e.target.value);
                      setProductName(
                        productNames[productCodes.indexOf(e.target.value)]
                      );
                    }}
                  >
                    {productCodes.map((code, index) => (
                      <option key={index} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-2">
                  <input
                    type="text"
                    id="reviewerName"
                    value={productName}
                    // onChange={(e) => setReviewerName(e.target.value)}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    disabled
                    placeholder="Product Name"
                  />
                </div>

                <div className="mb-2">
                  <select
                    id="productCode"
                    value={mediatorName}
                    style={{
                      backgroundColor: "#e8e8e8",
                      textAlign: "center",
                      color: "#000",
                      width: "100%",
                      padding: "10px",
                      marginTop: "5px",
                      borderRadius: "30px",
                    }}
                    onChange={(e) => setMediatorName(e.target.value)}
                  >
                    {mediatorNames.map((code, index) => (
                      <option key={index} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  type="submit"
                  className="btn-primary btn-block mb-4"
                  style={{
                    backgroundColor: "#386bc0",
                    textAlign: "center",
                    color: "#fff",
                    width: "100%",
                    padding: "10px",
                    marginTop: "15px",
                    borderRadius: "30px",
                  }}
                  disabled={isSubmitting} // Disable button while submitting
                >
                  {isSubmitting ? (
                    <Spinner animation="border" size="sm" /> // Show spinner while submitting
                  ) : (
                    "Save"
                  )}
                </button>
              </form>
              <Toaster /> {/* Render the toast container for notifications */}
              {/* Allotments Data Table using MDBTable */}
            </div>

            <MDBTable align="middle" hover responsive className="mt-4">
              <MDBTableHead
                style={{ backgroundColor: "#386bc0", color: "#fff" }}
              >
                <tr>
                  <th scope="col" style={{ color: "#fff", minWidth: "50px" }}>
                    #
                  </th>
                  <th scope="col" style={{ color: "#fff", minWidth: "100px" }}>
                    Product Code
                  </th>
                  <th scope="col" style={{ color: "#fff", minWidth: "100px" }}>
                    Product Name
                  </th>
                  <th scope="col" style={{ color: "#fff", minWidth: "150px" }}>
                    Mediator Name
                  </th>
                  <th scope="col" style={{ color: "#fff", minWidth: "150px" }}>
                    Status
                  </th>
                  <th scope="col" style={{ color: "#fff", minWidth: "150px" }}>
                    Action
                  </th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {allotments.length > 0 ? (
                  allotments.map((allotment, index) => (
                    <tr key={allotment.Id}>
                      <td>{index + 1}</td> {/* Serial Number */}
                      <td>{allotment.ProductCode}</td> {/* Product Code */}
                      <td>{allotment.ProductName}</td> {/* Product Code */}
                      <td>{allotment.MediatorName}</td> {/* Mediator Name */}
                      <td>{allotment.Status}</td> {/* Status */}
                      <td>
                        {console.log(allotment.Ids)}  
                        {allotment.Ids == null && allotment.Status == "Approved"? (<Button
                            color="primary"
                            style={{
                              backgroundColor: "#386bc0",
                              textAlign: "center",
                              color: "#fff",
                              width: "100%",
                              padding: "0px",
                              marginTop: "0px",
                              borderRadius: "30px",
                              position: "relative",
                            }}
                            onClick={() => {
                              if (allotment.Status === "Approved") {
                                Cookies.set(
                                  "MediatorName",
                                  allotment.MediatorName,
                                  {
                                    expires: 7,
                                  }
                                );
                                Cookies.set(
                                  "ProductCode",
                                  allotment.ProductCode,
                                  {
                                    expires: 7,
                                  }
                                );
                                Cookies.set(
                                  "ProductName",
                                  allotment.ProductName,
                                  {
                                    expires: 7,
                                  }
                                );
                                Cookies.set("AllotmentId", allotment.Id, {
                                  expires: 7,
                                });
                                history.push("/NewOrder");
                              }
                            }}
                          >
                            Order
                          </Button>) : null}
                      </td>{" "}
                      {/* Status */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No allotments found
                    </td>
                  </tr>
                )}
              </MDBTableBody>
            </MDBTable>
          </div>
        </div>
      </div>
    </div>
  );
}
