import React, { useEffect } from 'react';
import axios from 'axios';

const OAuthRedirectHandler = () => {
  useEffect(() => {
    // Assuming your backend is set to handle the redirect after authorization
    const handleAuthorization = async () => {
      const query = new URLSearchParams(window.location.search);
      const code = query.get('code');

      if (code) {
        try {
          // Send the authorization code to your backend to exchange for a token
          const response = await axios.post(`${process.env.REACT_APP_URI}/api/drive/oauth2callback`, { code });

          if (response.data) {
            alert('Authorization successful! You can now upload files.');
            window.location.href = '/NewOrder';
            // Optionally redirect to your file upload component or perform further actions
          }
        } catch (error) {
          console.error('Error handling authorization:', error);
          alert('Failed to authorize. Please try again.');
        }
      }
    };

    handleAuthorization();
  }, []);

  return <div>Processing authorization...</div>;
};

export default OAuthRedirectHandler;
