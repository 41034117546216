import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

export default function UpdatePassword1() {
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [loading, setLoading] = useState(false); // For the spinner
  const history = useHistory();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if password and confirm password match
    if (password !== cpassword) {
      toast.error("Passwords do not match");
      return;
    }

    // Retrieve userId from cookies
    const userId = Cookies.get("userforgetId");

    // Proceed to update the password
    setLoading(true); // Show spinner during submission
    try {
      const response = await axios.put(`${process.env.REACT_APP_URI}/UpdatePassword1`, {
        userId,
        newPassword: password,
      });

      // Check if password was successfully updated
      if (response.status === 200) {
        toast.success(response.data.message); // Show success message
        setTimeout(() => {
          history.push("/"); // Redirect to home after 1 second
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Failed to update password. Please try again.");
    } finally {
      setLoading(false); // Hide spinner after submission
    }
  };

  return (
    <div>
      <Toaster />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "80px",
        }}
      >
        <img
          src="./assets/logo.png"
          style={{ width: "70px", height: "auto" }}
          alt="logo"
        />
      </div>

      <h1 style={{ color: "#386bc0", marginTop: "20px" }}>Update Password</h1>
      <div
        className="row"
        style={{
          marginLeft: "0px",
          marginRight: "0px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "60px",
        }}
      >
        <form className="contact_form" onSubmit={handleSubmit}>
          <div className="col-lg-3 col-md-6 mx-auto text-left">
            <div data-mdb-input-init className="form-outline mb-2">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  backgroundColor: "#e8e8e8",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "30px",
                }}
                required
                placeholder="New Password"
              />
            </div>

            <div data-mdb-input-init className="form-outline mb-2">
              <input
                type="password"
                value={cpassword}
                onChange={(e) => setCPassword(e.target.value)}
                style={{
                  backgroundColor: "#e8e8e8",
                  textAlign: "center",
                  color: "#000",
                  width: "100%",
                  padding: "10px",
                  marginTop: "5px",
                  borderRadius: "30px",
                }}
                required
                placeholder="Confirm New Password"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-block mb-4"
              style={{
                backgroundColor: "#386bc0",
                textAlign: "center",
                color: "#000",
                width: "100%",
                padding: "10px",
                marginTop: "15px",
                borderRadius: "30px",
              }}
              disabled={loading} // Disable button while submitting
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Update Password"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
